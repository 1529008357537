<template>
  <div>
    <rxNavBar v-if="androidFlg == false" title="员工入职" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="员工入职"></rxNavBar>
    <!--        搜索框/状态-->
    <div class="head">
      <div class="searchDiv">
        <div class="search">
          <input placeholder="姓名/手机号/身份证号" v-model="searchValue" @keyup.enter="search">
        </div>
      </div>
      <!--            下拉-->
      <div class="downMenu" @click="showPicker = true">
        <div class="downMenuValue">{{ columns[number] ? columns[number].dictionaryTitle : '未审批' }}</div>
        <div :class="showPicker ? 'uparrows' : 'arrows'"></div>
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar :columns="columns" :default-index="number" value-key="dictionaryTitle" @cancel="showPicker = false"
                    @confirm="onConfirm"/>
      </van-popup>
    </div>
    <div class="blank-weight"></div>
    <div class="reviews">
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" v-if="orders.length!=0">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="onLoad"
        >
          <div class="review" v-for="(item,index) in orders" :key="index">
            <div :class="'top-title top-title-'+item.approvalStatus">
              {{ item.approvalStatusStr }}
            </div>
            <div class="vacationForm">
              <div class="vacationType"> <span>{{ item.staffName + '的入职申请' }}</span></div>
<!--              <div class="start_endTime"> <span>{{ item.vacationBeginTime  }}</span>-->
<!--                <span class="letter-sp">至</span>-->
<!--                <span>{{ item.vacationEndTime }}</span>-->
<!--              </div>-->
<!--              <div><span class="text">实际申请人:</span><span class="content">{{ item.vacationStaff }}</span></div>-->
              <div><span class="text">预计入职日期:</span><span class="content">{{ item.operDate }}{{item.openOrderFlag==='1'?'（开单入职）':''}}</span></div>
              <div><span class="text">部门:</span><span class="content">{{ item.departmentName}}</span></div>
              <div><span class="text">公司:</span><span class="content">{{ item.companyname}}</span></div>
              <div><span class="text">职位:</span><span class="content">{{ item.dutyName}}</span></div>
<!--              <div><span class="text">上级:</span><span class="content">{{ item.seniorStaffName }}</span></div>-->
<!--              <div><span class="text">上上级:</span><span class="content">{{ item.superiorStaffName }}</span></div>-->

            </div>
            <div class="line"></div>

            <div class="bottom">
<!--              <div class="time" v-if="item.approvalStatus==0">申请时间 : {{ item.addTime }}</div>-->
<!--              <div class="time" v-if="item.approvalStatus==2">驳回时间 : {{ item.addTime }}</div>-->
<!--              <div class="time" v-if="item.approvalStatus==1">通过时间 : {{ item.addTime }}</div>-->
              <div class="check" v-if="item.approvalStatus==1&&checkAuthList(authButtonsList,'senior')&&number===0"
                   @click="approve(item,'approve')">上级审核
              </div>
              <div class="check" v-if="item.approvalStatus==2&&checkAuthList(authButtonsList,'superior')&&number===0"
                   @click="approve(item,'approve')">上上级审核
              </div>
              <div class="check" v-if="item.approvalStatus==3&&checkAuthList(authButtonsList,'hrManager')&&number===0"
                   @click="approve(item,'approve')">人事审核
              </div>
              <div class="check" v-if="checkAuthList(authButtonsList,'revoke')&&(item.approvalStatus==1||item.approvalStatus==2||item.approvalStatus==3)&&number===2"
                   @click="revoke(item)">撤销
              </div>
              <div class="look" @click="approve(item,'detail')">查看</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <common-empty v-if="isEmptuyFlag"></common-empty>


    </div>
  </div>
</template>

<script>
import {checkAndroid, checkAuthList, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {
  getStaffFuntionModelList,
  mainTrialPlanRecordPage,
  queryBaseData,
  browseEmploymentApprovalList,
  approvalRrevoke
} from "../../../getData/getData";
import {
  Button,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar.vue";

export default {
  name: "staffEntry",
  components:{
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh,
  },
  data(){
    return{
      androidFlg:'',
      authButtonsList:[],
      refreshing: false,
      loading : true,
      finished: false,
      noContent:false,
      isEmptuyFlag: false,
      page:{currentPage:1,numberPage:10,},
      orders:[],
      count:0,
      //搜索/状态
      searchValue:'',
      showPicker:false,
      columns:[],
      number:0,
    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
    }
    next()
  },
  created() {
    this.getStaffFuntionModelList()
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    if (currentLabel != null) {
      if (currentLabel != '') {
        this.number = Number(currentLabel)
      }
    }
  },
  mounted() {
    this.checkPhoneorMobel()
    this.initDicData()
    this.getStaffFuntionModelList()
    this.finished = false;
    this.loading = true;
    this.refreshing = false
    this.orders=[]
    this.onLoad();
  },
  methods:{
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = []
      this.page =  {currentPage:1,numberPage:3}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.noContent = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    search(){
      if(this.searchValue!=''){
        localStorage.setItem('searchValue',JSON.stringify(this.searchValue))
      }else{
        localStorage.removeItem('searchValue')
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false
      this.loading = true
      this.noContent = false
      this.onLoad()
    },
    onConfirm(value, index){
      if(value){
        localStorage.setItem('currentLabel', JSON.stringify(index))
        this.number = index
      }
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.showPicker = false
      this.loading = true;
      this.noContent = false
      this.onLoad()
    },
    onLoad() {
      let that = this
      let queryData = {}
      // queryData.type='19'
      queryData.currentPage = that.page.currentPage
      queryData.numberPage = that.page.numberPage
      queryData.staff_id = getStaffId()
      queryData.approvalType = 0
      queryData.myApproval = this.number
      queryData.searchValue = this.searchValue
      browseEmploymentApprovalList(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          let list=response.data.data.data?response.data.data.data:[]
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          if(that.refreshing){
            that.refreshing = false
          }
          // 加载状态结束
          that.loading = false;
          that.page.currentPage++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          // 判断数据是否为空，空则显示空状态图
          if (that.orders.length == 0) {
            that.isEmptuyFlag = true;
          } else {
            that.isEmptuyFlag = false;
          }

          if (1 == that.page.currentPage && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
          }
        })
      })
    },

    approve(item,enterType){
      this.$router.push({
        name:'staffEntryDetail',
        query:{
          id:item.id,
          enterType:enterType,
          approval_id:item.approval_id,
          seniorStaffName:item.seniorStaffName,
          superiorStaffName:item.superiorStaffName,
        }
      });
    },
    revoke(item){
      Dialog.confirm({
        title: '友情提示',
        message: '确定撤回吗？',
      })
      .then(() => {
        let that = this;
        let initData = {};
        initData.staff_id = getStaffId();
        initData.id = item.id
        initData.approval_id = item.approval_id
        approvalRrevoke(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            responseUtil.alertOK(that)
            that.finished = false;
            that.orders = []
            that.page =  {currentPage:1,numberPage:3}
            that.loading = true;
            that.refreshing = false
            that.noContent = false
            that.onLoad();
          })
        })
      }).catch(() => {
      })
    },



    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    getStaffFuntionModelList () {
      var that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'staffEntry'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
    checkAuthList,
    //初始化字典数据
    initDicData: function () {
      const that = this
      let initData = {}
      initData.fdName = [ 'MYAPPROVALSTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.MYAPPROVALSTATUSMAP
          for (let i = 0; i < list.length; i++) {
            that.columns.push(list[i])
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
*{
  padding: 0;
  margin: 0;
}

//搜索+选择
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
  //搜索框
  .searchDiv {
    width: 100%;
    height: 40px;

    .search {
      width: 90%;
      height: 40px;
      margin: 10px auto;
      border-radius: 20px;
      background-color: #eeeeee;
      display: flex;

      input {
        width: 80%;
        background-color: #eeeeee;
        border: none;
        font-size: 15px;
      }
    }

    .search:before {
      content: '';
      display: block;
      background: url("../../../assets/images/search.png") no-repeat center center;
      background-size: 50% 50%;
      width: 40px;
      height: 40px;
    }

    input::placeholder {
      font-size: 15px;
    }
  }
  //下拉框
  .downMenu {
    height: 28px;
    width: 20%;
    margin: 15px 15px 0;
    line-height: 40px;
    display: flex;
    align-items: center;
    .downMenuValue {
      /*width: 14%;*/
      font-size: 14px;
      color: #FF5D3B;
    }

    .arrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: black transparent transparent transparent;
      margin-top: 1px;
      margin-left: 8px;
    }

    .uparrows {
      height: 0;
      width: 0;
      border: 3px solid;
      border-color: transparent transparent black transparent;
      margin-bottom: 6px;
      margin-left: 8px;
    }
  }
}

.blank-weight {
  height: 93px;
}
//列表
.reviews {
  overflow: hidden;
  position: relative;
}
.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.top-title{
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}

.top-title-4{
  background: linear-gradient(to right, #7cc7ff, #3596fd);
}
.top-title-5{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-0,.top-title-1,.top-title-2,.top-title-3{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-6{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-7{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}

/*入职表*/
.vacationForm {
  /*height: 168px;*/
  width: 315px;
  margin-left: 15px;
  margin-bottom: 15px;
  /*line-height: 30px;*/
}

.vacationForm div {
  line-height: 20px;
}

/*标题*/
.vacationType {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

/*时间*/
.start_endTime {
  color: #ff5809;
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}
/*行信息标题label*/
.text {
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.2rem;
  /*letter-spacing: 1px;*/
}
/*行信息内容*/
.content {
  font-size: 13px;
  color: #9a9a9a;
}
.line {
  width: 100%;
  border: 0.5px solid #D8D8D8;
}
/*    下部按钮*/
.bottom{
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0.3rem 0;
}

.check,.look{
  /*float: right;*/
  //margin-top: 0.2rem;
  margin-right: 0.5rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*按钮*/
.check{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  color: white;
}
.look{
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white,white),
  linear-gradient(to right ,#FFC274,#FF5D3B);
  background-clip: padding-box,border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
</style>